const sanityClient = require("@sanity/client")


// data input
// _type: "cartResume",
// title: "",
// cartJsonStr: `{}`,
// userInfoJsonStr: `{}`,
// cartTotal: ""

const newCart = (client, data) =>
  new Promise((resolve, reject) => {
    const docCart = {
      _type: "cartResume",
      _key: data.title,
      ...data,
    }
    client
      .patch("setting")
      .setIfMissing({ carts: [] })
      .insert("before", "carts[0]", [docCart])
      .commit({ autoGenerateArrayKeys: true })
      .then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
  })

export default async (data) => {
  const sanityOptions = {
    projectId: process.env.SANITY_TOKEN_ID,
    dataset: process.env.SANITY_STUDIO_API_DATASET,
    apiVersion: "2021-10-21",
    useCdn: true,
    token: process.env.SANITY_TOKEN_WRITE,
  }
  const client = await sanityClient(sanityOptions)
  newCart(client, data).then((res)=>{
    console.log("saved", res)
  }).catch(console.error)
}
